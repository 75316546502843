.button {
  display: flex;
  background-color: #4285F4;
  border-radius: 2px;
  border: none;
  color: #fff;
  height: 36px;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  text-decoration: none;
  box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
}

.button:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}

.wrapper {
  margin-top: 1px;
  margin-left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 2px;
  background-color: #fff
}

.icon {
  width: 18px;
  height: 18px;
}

.text {
  padding-left: 24px;
  padding-right: 8px;
  color: #fff;
  font-size: 14px;
  font-family: Roboto;
  flex-grow: 1;
  text-align: center;
  align-self: center;
}