.clock{ 
    font-size: 60%;
}

.navbar{
    justify-content: flex-end;
    z-index: 1051;
}

.navbar-brand{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;
}

.navbar .navbar-toggler{
    position: absolute;
    top: 10px;
}