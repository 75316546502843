.bottomright {
	position: absolute;
	bottom: 8px;
	right: 16px;
	font-size: 18px;
  }

.appBody{
	height: calc(100% - 64px);
	width: 100%;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.wrapperColumn{
	height: 100%;
	width: 360px;

	display: flex;
	flex-direction: column;

	flex-grow: 1 !important;
    flex-shrink: 1 !important;
    flex-basis: auto !important;
}

.tab-content>.active{
	height: 100%;
}

.verytop{
	z-index: 2000;
}

.columnBody{
  flex: 1;
	overflow: auto;
	display: flex;
  flex-direction: column;
}

.lastBlock{
	min-height: 320px;
}

.flexTabContent{
	display: flex;
	flex: 1;
}