.action-button {
    background-color: transparent;
    color: white;
    border: 0;
    opacity: 0.5;
}

.action-button:hover {
    background-color: transparent;
    color: white;
    border: 0;
    opacity: 1;
}

.gallery{
    min-height: 58px;
}