/* Inject styles */
@import '../components/react-table-factory/withFixedHeader';
@import '../components/react-table-factory/withSortingContext';
@import '../components/react-table-factory/table';

/* Inject adaptive behavior */
.adaptive-col-name {
    display: none;
}

@media all and (max-width: 940px) {
    
    .scrollable-table {
        @extend .adaptive-scrollable-table !optional;
    }

    .scrollable-table {
        .multi-line {
            display: inline-flex;
            justify-content: flex-end;
            
            div + div::before  {
                content: ',';
                margin-right: 5px;
            }
        }
    }
}

/* Add effects (not necessary) */
.selectable-row {
    perspective: 40em;
    transform: rotateX(0);
    // transition: color .1s linear;
}

@media all and (min-width: 941px) {
    .selectable-row:not(.selected-row):hover,
    .selected-row
    {
        animation: rotate .3s ease-out;
    }

    @keyframes rotate {
        0% {
            transform: rotateX(30deg);
        }
        50% {
            transform: rotateX(60deg);
        }
        100% {
            transform: rotateX(0);
        }
    }
}

.selectable-row:not(.selected-row):hover,
.selected-row
{
    animation: rotate .3s ease-out;
    color: rgb(52, 121, 241);
}

.scrollable-table {
    height: calc(100% - 47px);
}

table.default-theme {
    width: 100%;
    --bg-color: #fff;
    --header-ft-size: 16px;
    --cell-ft-size: 14px;
    --v-align: left;
    --cell-padding: 0px 0px 0px 0px;
    --border-color: #e7e9eb;
    --even-bg-color: #f9f9f9;
    --cell-height: 32px;
}

.scrollable-table > .scrollable-table-wrapper, .scrollable-table > .scrollable-table-content > .scrollable-table-wrapper {
    padding: 0px 2px;
}

.innerTd{
    height: 100%;
    padding: 4px 4px 4px 4px;
}

.innerTdHead{
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
    height: 100%;
}