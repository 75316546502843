
.card-control-prev-icon{
	background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    opacity: 0.2;
}

.card-control-prev-icon:hover {
    opacity: 1.0;
}

.card-control-next-icon{
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    opacity: 0.2;
}

.card-control-next-icon:hover {
    opacity: 1.0;
}

.clickable {
    cursor: pointer;
}

.hover { 
    z-index: -1; 
    opacity: 0.5; 
    transform-style:all; 
    transition-duration: 1s; 
} 